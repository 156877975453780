<template>
  <div class="app_page_container" style="display: flex;flex-direction: column;">
    <van-nav-bar
        title="选择小组类型"
        left-arrow
        @click-left="onClickLeft"
    />
    <div style="flex: 1;display: flex;flex-direction: column;align-items: center;justify-content: center;">
      <div @click="typeClick(item)" :class="['team_container', active === item.itemValue ? 'active' : '']"
           :key="item.itemValue"
           v-for="item in categoryItemVoList">
        {{ item.itemText }}
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import {Toast} from 'vant'
export default {
  name: "TeamType",
  data() {
    return {
      title: "选择小组类型",
      active: '',
    }
  },
  created() {
  },
  computed: {
    ...mapGetters('match', ['matchConfig', 'categoryItemVoList'])
  },
  methods: {
    ...mapMutations('match', ['SET_ACTIVE_CATEGORY_ITEM']),
    onClickLeft() {
      this.$router.back()
    },
    typeClick(item) {
      this.active = item.itemValue
      if (typeof this.active == "undefined") {
        Toast.fail('请选择小组类型')
        return
      }
      this.SET_ACTIVE_CATEGORY_ITEM(item)
      setTimeout(() => {
        this.$router.push({
          path: "/wx/form"
        })
      }, 200)
    }

  }
}
</script>

<style scoped>
.team_container {
  width: 70%;
  height: 120px;
  line-height: 120px;
  font-size: 1.2em;
  text-align: center;
  background-color: #FFF;
  border-radius: 4px;
  margin-bottom: 14px;
  color: #333;
  font-weight: bold;
}

.team_container.active {
  color: #fff;
  background-color: #333;
}
</style>
